
import { defineComponent } from '@vue/composition-api'

import ProfileCard from './ProfileCard.vue'
import { User } from '@/api/types/user'

export default defineComponent({
  name: 'ProfileView',
  components: {
    ProfileCard,
    ChangePasswordCard: () => import('@/views/profile/ChangePasswordCard.vue'),
  },
  setup(_, { root }) {
    const currentUser: User = root.$store.state.auth.currentUser
    const isUserLocal = () => {
      return currentUser.source === 'local'
    }
    return {
      isUserLocal,
    }
  },
})
