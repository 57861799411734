import { useAxios } from '@/composables/useAxios'
import { PromiseType } from '@/utils/types/PromiseType'

import { Contact, ContactLoggedIn, UseSendMessage } from './types/contact'

const VERSION = 'v1'
const RESOURCE = 'contact'

const useSendMessage = (): UseSendMessage => {
  const axios = useAxios<PromiseType<ReturnType<UseSendMessage['sendMessage']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  const sendMessage = (data: Contact | ContactLoggedIn) => axios.exec({ data })

  return {
    ...axios,
    sendMessage,
  }
}

export { useSendMessage }
