var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rounded-lg elevation-0",
    attrs: {
      "width": "1000"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('profile.title')) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-form', {
    ref: "profileForm",
    attrs: {
      "id": "profile-form",
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.editCurrentUser.apply(null, arguments);
      }
    }
  }, [_c('v-row', [_c('v-col', [_c('h5', {
    staticClass: "text-h5"
  }, [_vm._v(" " + _vm._s(_vm.$t('profile.edit.userdata')) + " ")])])], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.email'),
      "type": "email",
      "rules": [_vm.isRequired(_vm.editUser.email, _vm.$t('form.field.email')), _vm.isEmail(_vm.editUser.email, _vm.$t('form.field.email'))],
      "prepend-inner-icon": _vm.icons.mdiEmail,
      "outlined": "",
      "aria-required": ""
    },
    model: {
      value: _vm.editUser.email,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "email", $$v);
      },
      expression: "editUser.email"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.firstName'),
      "rules": [_vm.isRequired(_vm.editUser.firstName, _vm.$t('form.field.firstName'))],
      "outlined": "",
      "aria-required": ""
    },
    model: {
      value: _vm.editUser.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "firstName", $$v);
      },
      expression: "editUser.firstName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.lastName'),
      "rules": [_vm.isRequired(_vm.editUser.lastName, _vm.$t('form.field.lastName'))],
      "outlined": "",
      "aria-required": ""
    },
    model: {
      value: _vm.editUser.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "lastName", $$v);
      },
      expression: "editUser.lastName"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.phone'),
      "type": "tel",
      "prepend-inner-icon": _vm.icons.mdiPhone,
      "outlined": ""
    },
    model: {
      value: _vm.editUser.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "phone", $$v);
      },
      expression: "editUser.phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('form.field.mobile'),
      "type": "tel",
      "prepend-inner-icon": _vm.icons.mdiCellphone,
      "outlined": ""
    },
    model: {
      value: _vm.editUser.mobile,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "mobile", $$v);
      },
      expression: "editUser.mobile"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-divider')], 1)], 1), _c('v-row', [_c('v-col', [_c('h5', {
    staticClass: "text-h5"
  }, [_vm._v(" " + _vm._s(_vm.$t('profile.edit.usersettings')) + " ")])])], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "label": _vm.$tc('form.field.locale', 1),
      "rules": [_vm.isRequired(_vm.editUser.locale, _vm.$tc('form.field.locale', 1))],
      "items": _vm.implementedLocales,
      "outlined": ""
    },
    on: {
      "input": _vm.setCurrentLocale
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm.resolveCountry(item) !== '' ? _c('CommonFlagIcon', {
          staticClass: "mr-2",
          attrs: {
            "locale": _vm.resolveCountry(item)
          }
        }) : _vm._e(), _vm.resolveCountry(item) === '' ? _c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiEarth) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("locale.".concat(item))) + " ")];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item,
            on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-icon', {
          staticClass: "mr-2"
        }, [_vm.resolveCountry(item) !== '' ? _c('CommonFlagIcon', {
          attrs: {
            "locale": _vm.resolveCountry(item)
          }
        }) : _vm._e(), _vm.resolveCountry(item) === '' ? _c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiEarth))]) : _vm._e()], 1), _vm._v(" " + _vm._s(_vm.$t("locale.".concat(item))) + " ")], 1)];
      }
    }]),
    model: {
      value: _vm.editUser.locale,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "locale", $$v);
      },
      expression: "editUser.locale"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary",
      "type": "submit",
      "form": "profile-form"
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiCheck))]), _vm._v(" " + _vm._s(_vm.$t('form.action.changeProfile')) + " ")], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }