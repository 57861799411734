import { useAxios } from '@/composables/useAxios'

import {
  Translation,
  TranslationNestedMap,
  TranslationMap,
  UseGetTranslations,
  UseGetLocales,
  UseUpdateTranslation,
  UseUploadTranslationsFile,
  UseUpdateSingleTranslation,
  UseDeleteTranslation,
} from '@/api/types/translation'

const VERSION = 'v1'
const RESOURCE = 'translations'

export const useGetTranslations = (): UseGetTranslations => {
  const axios = useAxios<TranslationMap>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
  })

  const getTranslations = () => axios.exec()

  return {
    ...axios,
    getTranslations,
  }
}

export const useGetLocales = (): UseGetLocales => {
  const axios = useAxios<TranslationNestedMap>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}/locales`,
  })

  const getLocales = () => axios.exec()

  return {
    ...axios,
    getLocales,
  }
}

export const useUpdateTranslation = (): UseUpdateTranslation => {
  const axios = useAxios<Translation>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  const updateTranslation = (translation: Translation) => axios.exec({ data: translation })

  return {
    ...axios,
    updateTranslation,
  }
}

export const useUploadTranslationsFile = (): UseUploadTranslationsFile => {
  const axios = useAxios<void>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  const uploadTranslationsFile = (file: File, locale: string) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('locale', locale)

    return axios.exec({
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  return {
    ...axios,
    uploadTranslationsFile,
  }
}

export const useUpdateSingleTranslation = (): UseUpdateSingleTranslation => {
  const axios = useAxios<Translation>({
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  })

  const updateSingleTranslation = (translation: Translation) =>
    axios.exec({
      url: `/${VERSION}/${RESOURCE}/${translation.locale}/${translation.key}`,
      data: translation.value,
    })

  return {
    ...axios,
    updateSingleTranslation,
  }
}

export const useDeleteTranslation = (): UseDeleteTranslation => {
  const axios = useAxios<void>({
    method: 'DELETE',
  })

  const deleteTranslation = (translationKey: string) =>
    axios.exec({ url: `/${VERSION}/${RESOURCE}/delete/${translationKey}` })

  return {
    ...axios,
    deleteTranslation,
  }
}
