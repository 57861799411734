
import { defineComponent, computed, reactive, ref } from '@vue/composition-api'
import { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiCheck } from '@mdi/js'

import CommonFlagIcon from '@/components/common/CommonFlagIcon.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { resolveCountry } from '@/plugins/i18n'

import { useAuthActions, useNotify, useLocaleActions } from '@/store'

import { User, UserUpdate } from '@/api/types/user'

import { meApi } from '@/api'

import { ValidationForm } from '@/utils/types/validation'
import { isRequired, isEmail } from '@/utils/validation'
import { userToDto } from '@/utils/userToDto'

export default defineComponent({
  name: 'ProfileCard',
  components: {
    CommonFlagIcon,
    CommonAutocomplete,
  },
  setup(_, { root }) {
    const { updateCurrentUser } = meApi.useUpdateCurrentUser()
    const { addNotification } = useNotify()
    const { setCurrentUser } = useAuthActions()
    const { setCurrentLocale } = useLocaleActions()

    const profileForm = ref<ValidationForm | null>(null)

    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const currentUser = computed<User>(() => root.$store.state.auth.currentUser)

    const editUser: UserUpdate = reactive(userToDto(currentUser.value))

    const editCurrentUser = async () => {
      if (profileForm.value?.validate()) {
        updateCurrentUser(editUser)
          .then((user) => {
            setCurrentUser(user)

            addNotification({
              text: root.$t('profile.update.success') as string,
              type: 'success',
            })
          })
          .catch((error) => {
            error.userMessage = root.$t('profile.update.error')
            throw error
          })
      }
    }

    return {
      icons: { mdiEmail, mdiPhone, mdiCellphone, mdiEarth, mdiCheck },
      profileForm,
      isRequired,
      isEmail,
      setCurrentLocale,
      editUser,
      implementedLocales,
      editCurrentUser,
      resolveCountry,
    }
  },
})
