import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  NewUserRole,
  UserRoleUpdate,
  UseGetRoles,
  UseGetRole,
  UseCreateRole,
  UseUpdateRole,
  UseDeleteRole,
} from '@/api/types/role'
import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'roles'

export const useGetRoles = (): UseGetRoles => {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetRoles['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })

  return axios
}

export const useGetRole = (): UseGetRole => {
  const axios = useAxios<PromiseType<ReturnType<UseGetRole['getRole']>>>({
    method: 'GET',
  })

  const getRole = (roleId: string) => axios.exec({ url: `/${VERSION}/${RESOURCE}/${roleId}` })

  return {
    ...axios,
    getRole,
  }
}
export const useCreateRole = (): UseCreateRole => {
  const axios = useAxios<PromiseType<ReturnType<UseCreateRole['createRole']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  const createRole = (role: NewUserRole) => axios.exec({ data: role })

  return {
    ...axios,
    createRole,
  }
}
export const useUpdateRole = (): UseUpdateRole => {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateRole['updateRole']>>>({
    method: 'PUT',
  })

  const updateRole = (role: UserRoleUpdate) => axios.exec({ url: `/${VERSION}/${RESOURCE}/${role.name}`, data: role })

  return {
    ...axios,
    updateRole,
  }
}
export const useDeleteRole = (): UseDeleteRole => {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteRole['deleteRole']>>>({
    method: 'DELETE',
  })

  const deleteRole = (roleName: string) => axios.exec({ url: `/${VERSION}/${RESOURCE}/${roleName}` })

  return {
    ...axios,
    deleteRole,
  }
}
