import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PaginationParams } from '@/composables/types/usePagination'

import { UseGetRights } from '@/api/types/right'
import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'rights'

export const useGetRights = (): UseGetRights => {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetRights['getRights']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
  })

  const getRights = (paginationParams?: PaginationParams) => axios.exec({ params: paginationParams })

  return {
    ...axios,
    getRights,
  }
}
